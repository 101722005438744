import { useTranslation } from "react-i18next";
import { FC, HTMLAttributes, useCallback, useEffect, useState } from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { DeliveryFrequencyDetails } from "@/pages/subscription-manage/components/delivery-frequency-details.tsx";

import { SubscriptionData, SubscriptionInterval } from "@/core";
import { useUpdateSubscriptionInterval } from "@/hooks";

interface DeliveryFrequencyProps extends HTMLAttributes<HTMLDivElement> {
  subscriptionData: SubscriptionData;
}

export const DeliveryFrequency: FC<DeliveryFrequencyProps> = (props) => {
  const { subscriptionData } = props;
  const updateSubscriptionInterval = useUpdateSubscriptionInterval();
  const [isOpen, setIsOpen] = useState<boolean | undefined>();

  const { t } = useTranslation("translation");
  const { toast } = useToast();

  const onUpdateSubscription = useCallback(
    (value: SubscriptionInterval) => {
      updateSubscriptionInterval.mutate({
        interval: value,
      });
    },
    [updateSubscriptionInterval],
  );

  useEffect(() => {
    if (updateSubscriptionInterval.isSuccess) {
      toast({
        title: t("subscriptionUpdated"),
      });

      setIsOpen(false);
    }

    if (updateSubscriptionInterval.isError) {
      toast({
        title: t("subscriptionUpdateFailed"),
      });
    }
  }, [
    t,
    toast,
    updateSubscriptionInterval.isError,
    updateSubscriptionInterval.isSuccess,
  ]);

  return (
    <Card className={"col-span-3"}>
      <CardHeader className="flex justify-between">
        <CardTitle className={"font-hkgwb uppercase tracking-tight text-xl"}>
          {t("deliveryFrequency")}
        </CardTitle>
        <CardDescription className={"font-light"}>
          {t(subscriptionData.interval)}
        </CardDescription>
      </CardHeader>

      <CardContent className="flex justify-between">
        <DeliveryFrequencyDetails
          isOpen={isOpen}
          subscription={subscriptionData}
          setIsOpen={setIsOpen}
          triggerLabel={t("changeDeliveryFrequency")}
          primaryLabel={t("changeFrequency")}
          actionButtonLoading={updateSubscriptionInterval.isPending}
          actionButton={{
            label: t("changeFrequency"),
            onClick: onUpdateSubscription,
          }}
          subscriptionInterval={subscriptionData.interval}
        />
      </CardContent>
    </Card>
  );
};
