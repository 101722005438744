import { useTranslation } from "react-i18next";
import { FC, HTMLAttributes, useCallback, useMemo } from "react";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { Icons } from "@/components/ui/icons.tsx";
import { Button } from "@/components/ui/button.tsx";

interface ButtonProps {
  label: string;
  onClick: () => void;
}

interface OrderQuantity {
  name: string;
  value: number;
  onClick: (value: number) => void;
}

interface DialogFormHolderProps extends HTMLAttributes<HTMLDivElement> {
  triggerLabel?: string;
  primaryLabel?: string;
  secondaryLabel?: string;
  thirdLabel?: string;
  capsulePackageValue?: number;
  actionButton: ButtonProps;
  quantityChange?: ButtonProps[];
  orderQuantity?: OrderQuantity[];
  totalQuantity?: number;
  currentQuantity?: number;
  actionButtonLoading?: boolean;
  isOpen: boolean | undefined;
  setIsOpen: (value: boolean) => void;
}

export const ChangeSubscriptionDetails: FC<DialogFormHolderProps> = ({
  actionButton,
  actionButtonLoading,
  quantityChange,
  secondaryLabel,
  capsulePackageValue,
  triggerLabel,
  isOpen,
  setIsOpen,
  orderQuantity,
  thirdLabel,
  primaryLabel,
  currentQuantity,
  totalQuantity,
}) => {
  const { t } = useTranslation("translation");
  const isAbleToIncreaseCapsules = useMemo(() => {
    if (!totalQuantity || !currentQuantity) {
      return;
    }

    return currentQuantity >= totalQuantity;
  }, [totalQuantity, currentQuantity]);

  const capsulesColor = useCallback((name: string) => {
    if (name === "Espresso") {
      return "bg-brand-celezte";
    }

    if (name === "Ristretto") {
      return "bg-brand-black";
    }

    if (name === "Lungo") {
      return "bg-white border";
    }

    if (name === "Decaf") {
      return "bg-brand-powder-blue";
    }

    return "";
  }, []);

  return (
    <Drawer open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DrawerTrigger className={"w-full"} asChild>
        <Button variant={"black"} size={"sm"} className="text-sm">
          {triggerLabel}
        </Button>
      </DrawerTrigger>

      <DrawerContent
        className={
          "lg:max-w-3xl lg:w-3xl w-full max-h-[95vh] lg:max-h-[100vh] mx-auto"
        }
      >
        <DrawerHeader>
          <div>
            {primaryLabel && (
              <DrawerTitle className={"text-center font-hkgwb uppercase"}>
                {primaryLabel}
              </DrawerTitle>
            )}

            {secondaryLabel && (
              <DrawerTitle className={"text-center font-hkgwb uppercase"}>
                {t("changeCapsulesQuantity")}
              </DrawerTitle>
            )}
          </div>

          <DrawerDescription className={"text-center mb-1"}>
            {t("changeCapsulesQuantityDescription")}
          </DrawerDescription>
        </DrawerHeader>

        <div className={"overflow-auto px-8"}>
          {quantityChange && (
            <div className={"grid grid-cols-2 gap-2"}>
              {quantityChange.map((item, index) => {
                const variant = item.label.includes(`${capsulePackageValue}`)
                  ? "celezte-light"
                  : "outline";

                return (
                  <Button
                    key={index}
                    variant={variant}
                    size={"sm"}
                    type={"button"}
                    onClick={item.onClick}
                    className="break-all text-wrap"
                  >
                    {item.label}
                  </Button>
                );
              })}
            </div>
          )}

          {thirdLabel && (
            <DrawerTitle className={"text-center my-12 font-hkgwb uppercase"}>
              {t("changeCapsulesBlends")}
            </DrawerTitle>
          )}

          {orderQuantity && (
            <div className={"grid grid-cols-2 lg:grid-cols-4 gap-2 "}>
              {orderQuantity.map((item, index) => (
                <div
                  key={index}
                  className={
                    "flex flex-col w-full py-2 text-center bg-secondary rounded-lg"
                  }
                >
                  <div className={"flex justify-center"}>
                    <div
                      className={`w-4 h-4 ${capsulesColor(item.name)} rounded-full mt-2`}
                    >
                      {/*<Icons.capsule />*/}
                    </div>
                  </div>
                  <div className={"mt-4"}>
                    <DrawerTitle className={"font-extralight"}>
                      {item.name}
                    </DrawerTitle>
                  </div>

                  <div
                    className={"flex items-center justify-center gap-2 my-2"}
                  >
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => {
                        const { onClick } = item;

                        if (item.value === 0) {
                          return;
                        }

                        onClick(item.value - 10);
                      }}
                    >
                      <Icons.minus />
                    </Button>
                    <DrawerTitle
                      className={"font-semibold w-8 text-foreground"}
                    >
                      {item.value}
                    </DrawerTitle>
                    <Button
                      variant="outline"
                      size="icon"
                      disabled={isAbleToIncreaseCapsules}
                      onClick={() => {
                        const { onClick } = item;

                        onClick(item.value + 10);
                      }}
                    >
                      <Icons.plus />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <DrawerFooter>
          <div className={"w-full items-center gap-4 h-full"}>
            <div className={"flex items-center justify-between "}>
              <DrawerClose className={"w-fit"}>
                <Button variant={"outline"} size={"sm"}>
                  {t("close")}
                </Button>
              </DrawerClose>

              <DrawerTitle className={"text-center"}>
                {currentQuantity} / {totalQuantity}
              </DrawerTitle>

              <Button
                variant={"black"}
                size={"sm"}
                disabled={
                  actionButtonLoading || totalQuantity !== currentQuantity
                }
                onClick={actionButton.onClick}
              >
                {actionButtonLoading ? <Icons.loading /> : actionButton.label}
              </Button>
            </div>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
