import { useTranslation } from "react-i18next";
import { FC, HTMLAttributes, useEffect, useState } from "react";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { ChangeSubscriptionDetails } from "@/pages/subscription-manage/components/change-subscription-details.tsx";

import { useUpdateSubscriptionCapsulesAndQuantityPackage } from "@/hooks";

import {
  CapsulesConfig,
  ProductBundleCapsulesQuantity,
  SubscriptionData,
} from "@/core";

interface CapsulesContainerProps extends HTMLAttributes<HTMLDivElement> {
  subscription: SubscriptionData;
}

export const CapsulesContainer: FC<CapsulesContainerProps> = (props) => {
  const { className, subscription } = props;
  const { t } = useTranslation("translation");
  const { toast } = useToast();
  const [isOpen, setIsOpen] = useState<boolean | undefined>();

  const items = subscription.subscriptionProduct.capsulesConfig;
  const [capsules, setCapsules] = useState<CapsulesConfig>(items);

  const [capsulePackage, setCapsulePackage] = useState(
    subscription.subscriptionProduct.capsulesQuantity,
  );

  const packageQuantityLabel = `${subscription.subscriptionProduct.capsulesQuantity} ${t("capsules")}`;

  const updateSubscription = useUpdateSubscriptionCapsulesAndQuantityPackage();

  const onUpdateSubscription = (value: CapsulesConfig) => {
    updateSubscription.mutate({
      capsulesQuantity: capsulePackage,
      capsulesConfig: value,
    });
  };

  useEffect(() => {
    if (updateSubscription.isSuccess) {
      toast({
        title: t("subscriptionUpdated"),
      });

      setIsOpen(false);
    }

    if (updateSubscription.isError) {
      toast({
        title: t("subscriptionFailedToUpdate"),
      });
    }
  }, [t, toast, updateSubscription.isError, updateSubscription.isSuccess]);

  return (
    <Card className={className}>
      <CardHeader>
        <h3 className={"font-hkgwb uppercase tracking-tight text-xl"}>
          {t("capsules")}
        </h3>
        <p className={"font-light mt-2"}>
          {t("yourCurrentPlanHas")}{" "}
          <span className={"font-bold"}>{packageQuantityLabel}</span>
        </p>
      </CardHeader>
      <CardContent>
        <div className={"grid grid-cols-1 gap-2 my-6"}>
          <div className={"flex justify-between font-light"}>
            <div className={"flex items-center gap-2"}>
              <div className={"w-4 h-4 bg-brand-celezte rounded-full"}>
                {/*<Icons.capsule />*/}
              </div>
              <p className={"font-hkgwl uppercase"}>Espresso</p>
            </div>
            <p>
              {items.espresso} {t("capsules")}
            </p>
          </div>

          <div className={"flex justify-between font-light"}>
            <div className={"flex items-center gap-2"}>
              <div className={"w-4 h-4 bg-brand-black rounded-full"}>
                {/*<Icons.capsule />*/}
              </div>
              <p className={"font-hkgwl uppercase"}> Ristretto </p>
            </div>
            <p>
              {items.ristretto} {t("capsules")}
            </p>
          </div>

          <div className={"flex justify-between font-light"}>
            <div className={"flex items-center gap-2"}>
              <div className={"w-4 h-4 bg-white border rounded-full"}>
                {/*<Icons.capsule />*/}
              </div>
              <p className={"font-hkgwl uppercase"}>Lungo</p>
            </div>
            <p>
              {items.lungo} {t("capsules")}
            </p>
          </div>

          <div className={"flex justify-between font-light"}>
            <div className={"flex items-center gap-2"}>
              <div className={"w-4 h-4 bg-brand-powder-blue rounded-full"}>
                {/*<Icons.capsule />*/}
              </div>
              <p className={"font-hkgwl uppercase"}>Decaf</p>
            </div>
            <p>
              {items.decaf} {t("capsules")}
            </p>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <ChangeSubscriptionDetails
          triggerLabel={t("updatePreferences")}
          secondaryLabel={"Change capsules quantity"}
          thirdLabel={"Change capsules blends"}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          quantityChange={[
            {
              label: `60 ${t("capsules")}`,
              onClick: () => {
                setCapsulePackage(ProductBundleCapsulesQuantity.SIXTY);

                setCapsules({
                  lungo: 20,
                  espresso: 20,
                  ristretto: 20,
                  decaf: 0,
                });
              },
            },
            {
              label: `120 ${t("capsules")}`,
              onClick: () => {
                setCapsulePackage(
                  ProductBundleCapsulesQuantity.ONE_HUNDRED_TWENTY,
                );

                setCapsules({
                  lungo: 40,
                  espresso: 40,
                  ristretto: 40,
                  decaf: 0,
                });
              },
            },
            {
              label: `250 ${t("capsules")}`,
              onClick: () => {
                setCapsulePackage(
                  ProductBundleCapsulesQuantity.TWO_HUNDRED_FIFTY,
                );

                setCapsules({
                  lungo: 80,
                  espresso: 80,
                  ristretto: 80,
                  decaf: 10,
                });
              },
            },
            {
              label: `500 ${t("capsules")}`,
              onClick: () => {
                setCapsulePackage(ProductBundleCapsulesQuantity.FIVE_HUNDRED);

                setCapsules({
                  lungo: 160,
                  espresso: 160,
                  ristretto: 160,
                  decaf: 20,
                });
              },
            },
          ]}
          orderQuantity={[
            {
              name: "Espresso",
              value: capsules.espresso,
              onClick: (value) => setCapsules({ ...capsules, espresso: value }),
            },
            {
              name: "Ristretto",
              value: capsules.ristretto,
              onClick: (value) =>
                setCapsules({ ...capsules, ristretto: value }),
            },
            {
              name: "Lungo",
              value: capsules.lungo,
              onClick: (value) => setCapsules({ ...capsules, lungo: value }),
            },
            {
              name: "Decaf",
              value: capsules.decaf,
              onClick: (value) => setCapsules({ ...capsules, decaf: value }),
            },
          ]}
          capsulePackageValue={capsulePackage}
          currentQuantity={
            capsules.lungo +
            capsules.espresso +
            capsules.ristretto +
            capsules.decaf
          }
          totalQuantity={capsulePackage}
          actionButtonLoading={updateSubscription.isPending}
          actionButton={{
            label: t("save"),
            onClick: () => onUpdateSubscription(capsules),
          }}
        />
      </CardFooter>
    </Card>
  );
};
