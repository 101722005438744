import { Link, useLocation } from "react-router-dom";
import { FC, HTMLAttributes } from "react";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button.tsx";
import { RoutePath } from "@/routes/constants/route-paths.ts";
import { useTranslation } from "react-i18next";

interface NavigationItem {
  href: string;
  label: string;
}

interface SidebarNavProps extends HTMLAttributes<HTMLElement> {}

export const SidebarNav: FC<SidebarNavProps> = (props) => {
  const { className } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation("translation");

  const navigationItems: NavigationItem[] = [
    {
      href: RoutePath.SUBSCRIPTION,
      label: t("subscription"),
    },
    {
      href: RoutePath.SUBSCRIPTION_MANAGE,
      label: t("manageSubscription"),
    },
    {
      href: RoutePath.SUBSCRIPTION_ORDERS,
      label: t("orderHistory"),
    },
    {
      href: RoutePath.SUBSCRIPTION_PROFILE,
      label: t("myProfile"),
    },
  ];
  return (
    <nav
      className={cn(
        "hidden sm:flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1 sticky top-2 h-auto",
        className,
      )}
      {...props}
    >
      {navigationItems.map((item) => (
        <Link
          key={item.href}
          to={item.href}
          className={cn(
            "uppercase",
            buttonVariants({ variant: "ghost" }),
            pathname === item.href
              ? "bg-brand-powder-blue hover:bg-brand-powder-blue"
              : "hover:bg-transparent hover:underline",
            "justify-start",
            "font-hkgwl",
          )}
        >
          {item.label}
        </Link>
      ))}
    </nav>
  );
};
