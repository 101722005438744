import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useGetCustomer, useGetSubscription } from "@/hooks";

import { ErrorState } from "@/components/shared/ErrorState.tsx";
import { EmptyState } from "@/components/shared/EmptyState.tsx";
import { LoadingState } from "@/components/shared/LoadingState.tsx";

import { Button } from "@/components/ui/button.tsx";
import { DiscountCode } from "@/pages/subscription-manage/components/discount-code.tsx";
import { CancelOrSkip } from "@/pages/subscription-manage/components/cancel-or-skip.tsx";
import { DeliveryAddress } from "@/pages/subscription-manage/components/delivery-address.tsx";
import { NextBillingDate } from "@/pages/subscription-manage/components/next-billing-date.tsx";
import { DeliveryFrequency } from "@/pages/subscription-manage/components/delivery-frequency.tsx";
import { CapsulesContainer } from "@/pages/subscription-manage/components/capsules-container.tsx";

import { useAuthUser } from "@/hooks/service-hooks/auth/use-auth.ts";
import { useProtectedPage } from "@/hooks/use-protect-page.ts";

import { OuterPath } from "@/routes/constants/route-paths.ts";
import { PaymentMethod } from "@/pages/subscription-manage/components/payment-method.tsx";

export function SubscriptionManagePage() {
  const { t } = useTranslation("translation");
  const authUser = useAuthUser();
  useProtectedPage();

  const {
    data: customer,
    isLoading: isCustomerLoading,
    error: customerError,
  } = useGetCustomer({
    id: authUser?.uid || "",
  });

  const subscription = useGetSubscription({
    id: authUser?.uid || "",
  });

  if (isCustomerLoading) {
    return <LoadingState />;
  }

  if (!customer || !authUser) {
    return <EmptyState />;
  }

  if (customerError) {
    return <ErrorState />;
  }

  return (
    <>
      {!subscription.data && (
        <div className={"flex justify-center"}>
          <Link to={OuterPath.TEST_PACKAGE_URL} target={"_blank"}>
            <Button>{t("subscribe")}</Button>
          </Link>
        </div>
      )}

      {subscription.data && (
        <div className={"py-12 lg:py-0 space-y-4"}>
          <div className={""}>
            <h1 className={"text-2xl font-hkgwb uppercase"}>
              {t("manageSubscription")}
            </h1>
            <p className={"font-light mt-2"}>
              {t("manageSubscriptionDescription")}
            </p>
          </div>
          <div
            className={"grid grid-cols-1 lg:grid-cols-2 gap-8 grid-flow-row"}
          >
            <CapsulesContainer subscription={subscription.data} />

            <div className={" w-full space-y-6"}>
              <DeliveryFrequency subscriptionData={subscription.data} />

              <NextBillingDate subscriptionData={subscription.data} />
            </div>

            <PaymentMethod
              lastFourDigitsCode={customer.card.maskedCardNumber}
              expiresDate={customer.card.expirationDate}
            />

            <DiscountCode subscription={subscription.data} />

            <DeliveryAddress address={customer.shippingAddress} />

            <CancelOrSkip subscription={subscription.data} />
          </div>
        </div>
      )}
    </>
  );
}
