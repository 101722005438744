import { FC, HTMLAttributes, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import ReactPlayer from 'react-player';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useToast } from "@/components/ui/use-toast.ts";
import { CancelSubscriptionReasonForm } from "@/components/forms/cancel-subscription-reason-form.tsx";

import { useCancelSubscription } from "@/hooks";

import { SubscriptionCancelReason } from "@/core";
// import { ScrollArea } from "@/components/ui/scroll-area.tsx";

interface PauseSubscriptionProps extends HTMLAttributes<HTMLDivElement> {}

export const CancelSubscription: FC<PauseSubscriptionProps> = () => {
  const { t } = useTranslation("translation");
  const { toast } = useToast();

  const [isOpen, setIsOpen] = useState<boolean | undefined>();

  const cancelSubscription = useCancelSubscription();

  const onSubmit = useCallback(
    (data: SubscriptionCancelReason) => {
      if (data.cancelReason === "otherReason") {
        if (!data.otherReason) {
          toast({
            title: t("pleaseEnterReason"),
          });
          return;
        }
      }

      cancelSubscription.mutate({
        reason:
          data.cancelReason === "otherReason"
            ? data.otherReason || ""
            : data.cancelReason,
      });
    },
    [cancelSubscription, t, toast],
  );

  useEffect(() => {
    if (cancelSubscription.isSuccess) {
      toast({
        title: t("subscriptionUpdated"),
      });

      setIsOpen(false);
    }
  }, [toast, cancelSubscription.isSuccess, t]);

  useEffect(() => {
    if (cancelSubscription.isError) {
      toast({
        title: t("subscriptionUpdateFailed"),
      });
    }
  }, [cancelSubscription.isError, t, toast]);

  return (
    <AlertDialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <AlertDialogTrigger asChild>
        <Button variant="invisible" className={"justify-end"}>
          {t("cancel")}
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent
        className={
          "lg:max-w-3xl max-w-[90vw] max-h-[70vh] rounded-lg overflow-y-auto"
        }
      >
        <AlertDialogHeader>
          <AlertDialogTitle className={"text-center font-hkgwb uppercase"}>
            {t("cancelSubscription")}
          </AlertDialogTitle>
        </AlertDialogHeader>

        <div className={"flex justify-center mt-2"}>
          <div className={"text-center"}>
            {t("cancelSubscriptionDescription")} <br />
            {t("cancelSubscriptionSecondDescription")}
          </div>
        </div>

        {/*<ScrollArea className="h-full sm:h-[50vh] w-full p-4">*/}
        {/*<div className={'flex w-full justify-center'}>*/}
        {/*  <ReactPlayer*/}
        {/*    width={'auto'}*/}
        {/*    height={'auto'}*/}
        {/*    playing={true}*/}
        {/*    loop={true}*/}
        {/*    url="https://vimeo.com/4131364"*/}
        {/*  />*/}
        {/*</div>*/}

        <div className={"flex justify-center mt-2"}>
          <CancelSubscriptionReasonForm
            isLoading={cancelSubscription.isPending}
            onSubmit={onSubmit}
          />
        </div>
        {/*</ScrollArea>*/}

        <div className={"flex justify-center"}>
          <AlertDialogCancel asChild>
            <Button variant={"secondary"} className={"max-w-32"}>
              {t("back")}
            </Button>
          </AlertDialogCancel>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};
