import { useTranslation } from "react-i18next";
import { FC, HTMLAttributes, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Icons } from "@/components/ui/icons.tsx";

import {
  SubscriptionData,
  SubscriptionInterval,
  SubscriptionStatus,
} from "@/core";

interface ButtonProps {
  label: string;
  onClick: (value: SubscriptionInterval) => void;
}

interface DeliveryFrequencyButtonProps {
  label: string;
  interval: SubscriptionInterval;
}

interface DialogFormHolderProps extends HTMLAttributes<HTMLDivElement> {
  triggerLabel?: string;
  primaryLabel?: string;

  actionButton: ButtonProps;
  deliveryFrequencies?: DeliveryFrequencyButtonProps[];

  totalQuantity?: number;
  currentQuantity?: number;
  actionButtonLoading?: boolean;
  subscriptionInterval: SubscriptionInterval;

  isOpen: boolean | undefined;
  setIsOpen: (value: boolean) => void;
  subscription: SubscriptionData;
}

export const DeliveryFrequencyDetails: FC<DialogFormHolderProps> = (props) => {
  const {
    actionButton,
    actionButtonLoading,
    totalQuantity,
    subscriptionInterval,
    isOpen,
    setIsOpen,
    currentQuantity,
    subscription,
    triggerLabel,
  } = props;
  const { t } = useTranslation("translation");

  const deliveryFrequencies = [
    // {
    //   label: t("everyTwoWeeks"),
    //   interval: SubscriptionInterval.TWO_WEEKS,
    // },
    {
      label: t("everyFourWeeks"),
      interval: SubscriptionInterval.FOUR_WEEKS,
    },
    {
      label: t("everySixWeeks"),
      interval: SubscriptionInterval.SIX_WEEKS,
    },
    {
      label: t("everyEightWeeks"),
      interval: SubscriptionInterval.EIGHT_WEEKS,
    },
    {
      label: t("everyTwelveWeeks"),
      interval: SubscriptionInterval.TWELVE_WEEKS,
    },
  ];

  const [selectedFrequency, setSelectedFrequency] =
    useState<SubscriptionInterval>(subscriptionInterval);

  return (
    <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DialogTrigger className={"w-full"} asChild>
        <Button
          disabled={
            subscription.status === SubscriptionStatus.CANCELLED ||
            subscription.status === SubscriptionStatus.FAILED
          }
          variant={"black"}
          size={"sm"}
          className="text-sm"
        >
          {triggerLabel}
        </Button>
      </DialogTrigger>

      <DialogContent className={"max-w-3xl"}>
        <DialogHeader>
          <div>
            <DialogTitle className={"text-center mb-12 font-hkgwb uppercase"}>
              {t("changeDeliveryFrequency")}
            </DialogTitle>

            {deliveryFrequencies && deliveryFrequencies.length > 0 && (
              <div className={"grid grid-cols-2 gap-2"}>
                {deliveryFrequencies.map((item, index) => {
                  const isActive = item.interval === selectedFrequency;
                  return (
                    <Button
                      key={index}
                      disabled={subscriptionInterval === item.interval}
                      variant={isActive ? "celezte-light" : "outline"}
                      size={"sm"}
                      onClick={() => {
                        setSelectedFrequency(item.interval);
                      }}
                    >
                      {item.label}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        </DialogHeader>

        <DialogFooter>
          <div className={"w-full items-center gap-4 h-full"}>
            {!deliveryFrequencies && (
              <DialogTitle className={"text-center"}>
                {currentQuantity} / {totalQuantity}
              </DialogTitle>
            )}
            <div className={"flex justify-center my-5"}>
              <Button
                variant={"black"}
                size={"sm"}
                disabled={
                  selectedFrequency === subscriptionInterval ||
                  actionButtonLoading
                }
                onClick={() => {
                  actionButton.onClick(selectedFrequency);
                }}
                className="w-full max-w-32"
              >
                {actionButtonLoading ? <Icons.loading /> : t("save")}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
