import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "@/components/ui/input.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";

import { useCancelSubscriptionReasonForm } from "@/hooks/forms/entities/form/use-cancel-subscription-reason-form.ts";

import { SubscriptionCancelReason } from "@/core";

import { Icons } from "../ui/icons";

type SelectItemType = { value: string; label: string };

interface CancelSubscriptionReasonFormProps {
  onSubmit: (data: SubscriptionCancelReason) => void;
  isLoading: boolean;
}

export const CancelSubscriptionReasonForm: FC<
  CancelSubscriptionReasonFormProps
> = (props) => {
  const { onSubmit, isLoading } = props;
  const { t } = useTranslation("translation");

  const form = useCancelSubscriptionReasonForm({
    defaultValues: {
      cancelReason: "otherReason", // Default reason MUST be "otherReason"
      otherReason: "",
    },
    mode: "onChange",
  });

  const cancelReason = form.watch("cancelReason");

  // Create shuffle function that will be stable across renders
  const shuffle = useCallback(<T,>(array: T[]): T[] => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }, []);

  // Define base items array without "otherReason"
  const baseItems = useMemo(
    (): SelectItemType[] => [
      { value: t("itsTooExpensive"), label: t("itsTooExpensive") },
      { value: t("wrongOrder"), label: t("wrongOrder") },
      { value: t("tooMuchCoffee"), label: t("tooMuchCoffee") },
      { value: t("needFasterDelivery"), label: t("needFasterDelivery") },
      { value: t("stoppedDrinkingCoffee"), label: t("stoppedDrinkingCoffee") },
      { value: t("coffeeProblem"), label: t("coffeeProblem") },
    ],
    [t],
  );

  // Create randomized items only once when component mounts
  const selectItems = useMemo(() => {
    const shuffledItems = shuffle<SelectItemType>(baseItems);
    const allItems: SelectItemType[] = [
      ...shuffledItems,
      { value: "otherReason", label: t("otherReason") },
    ];

    return allItems.map((item) => (
      <SelectItem key={item.value} value={item.value}>
        {item.label}
      </SelectItem>
    ));
  }, [shuffle, baseItems, t]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-2/3 space-y-6">
        <FormField
          control={form.control}
          name="cancelReason"
          render={({ field }) => (
            <FormItem>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("itsTooExpensive")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>{selectItems}</SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="otherReason"
          render={({ field }) => (
            <FormItem>
              {cancelReason === "otherReason" && (
                <>
                  <div className={"pt-8"}>
                    <p className={"text-xl font-medium text-center pb-4"}>
                      {t("cancelQuestion")}
                    </p>
                    <Input {...field} />
                  </div>
                  <FormMessage />
                </>
              )}
            </FormItem>
          )}
        />
        <div className={"flex justify-center gap-10"}>
          <Button disabled={isLoading} variant="destructive">
            {isLoading && <Icons.loading />}
            {t("cancelSubscription")}
          </Button>
        </div>
      </form>
    </Form>
  );
};
