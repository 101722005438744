import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/button.tsx";
import { ShippingAddress } from "@/components/shared/ShippingAddress.tsx";
import { ChangeDeliveryAddressForm } from "@/components/forms/change-delivery-address-form.tsx";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";

import { Address } from "@/core";

interface DeliveryAddressProps {
  address: Address;
}

export const DeliveryAddress: FC<DeliveryAddressProps> = (props) => {
  const { address } = props;
  const { t } = useTranslation("translation");
  const [open, setOpen] = useState<boolean | undefined>(undefined);

  return (
    <Card className={"flex flex-col justify-between"}>
      <CardHeader className={"flex justify-between"}>
        <CardTitle className={"text-xl font-hkgwb uppercase"}>
          {t("deliveryAddress")}
        </CardTitle>
        <CardDescription className={"font-light mt-2"}></CardDescription>
      </CardHeader>
      <CardContent>
        <ShippingAddress address={address} />
      </CardContent>

      <CardFooter>
        <Dialog
          open={open}
          onOpenChange={(open) => {
            setOpen(open);
          }}
        >
          <DialogTrigger asChild className={"w-full"}>
            <Button variant={"black"}>{t("editDeliveryAddress")}</Button>
          </DialogTrigger>
          <DialogContent className={"max-h-[70vh] overflow-auto"}>
            <DialogHeader>
              <DialogTitle className={"text-center mb-6 font-hkgwb uppercase"}>
                {t("editDeliveryAddress")}
              </DialogTitle>
            </DialogHeader>
            <ChangeDeliveryAddressForm
              defaultValues={address}
              closeDialog={() => setOpen(false)}
            />
          </DialogContent>
        </Dialog>
      </CardFooter>
    </Card>
  );
};
