import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { FC, useCallback, useEffect } from "react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input.tsx";
import { useToast } from "@/components/ui/use-toast.ts";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { useUpdateCustomerAddress } from "@/hooks";
import { useAuthUser } from "@/hooks/service-hooks/auth/use-auth.ts";
import { useChangeDeliveryAddressForm } from "@/hooks/forms/entities/form/use-change-delivery-address-form.ts";

import { Address } from "@/core";

import { Icons } from "../ui/icons";

import "react-phone-input-2/lib/style.css";

interface ChangeDeliveryAddressFormProps {
  defaultValues: Address;
  closeDialog: () => void;
}

export const ChangeDeliveryAddressForm: FC<ChangeDeliveryAddressFormProps> = ({
  defaultValues,
  closeDialog,
}) => {
  const { toast } = useToast();

  const authUser = useAuthUser();
  const form = useChangeDeliveryAddressForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const { t } = useTranslation("translation");

  const updateCustomerAddress = useUpdateCustomerAddress();

  const onSubmit = useCallback(
    (values: Address) => {
      if (!authUser) {
        return;
      }

      updateCustomerAddress.mutate({
        address: {
          address: values.address,
          address2: values.address2,
          city: values.city,
          country: values.country,
          phone: values.phone,
          postalCode: values.postalCode,
          company: values.company || "",
        },
      });
    },
    [authUser, updateCustomerAddress],
  );

  useEffect(() => {
    if (updateCustomerAddress.isSuccess) {
      toast({
        title: t("customerUpdated"),
      });
      closeDialog();
    }
  }, [closeDialog, t, toast, updateCustomerAddress.isSuccess]);

  useEffect(() => {
    if (updateCustomerAddress.isError) {
      toast({
        title: t("customerUpdateFailed"),
      });
      closeDialog();
    }
  }, [closeDialog, t, toast, updateCustomerAddress.isError]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("address")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                {/*This is your public display name.*/}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="address2"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("address2")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                {/*This is your public display name.*/}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="city"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("city")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                {/*This is your public display name.*/}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="postalCode"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("postalCode")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                {/*This is your public display name.*/}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="country"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("country")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                {/*This is your public display name.*/}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("phone")}</FormLabel>
              <FormControl>
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  country={"se"}
                  {...field}
                  onChange={(value) => {
                    // add + to the phone number
                    field.onChange(value.startsWith("+") ? value : `+${value}`);
                    console.log("value", field.value);
                  }}
                  // onChange={field.onChange}
                  autoFormat={false}
                />
              </FormControl>
              <FormDescription>
                {/*This is your public display name.*/}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="company"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("company")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                {/*This is your public display name.*/}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className={"flex justify-center"}>
          <Button
            variant={"black"}
            disabled={
              updateCustomerAddress.isPending || !form.formState.isDirty
            }
            type="submit"
          >
            {updateCustomerAddress.isPending ? <Icons.loading /> : t("save")}
          </Button>
        </div>
      </form>
    </Form>
  );
};
