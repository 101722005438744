import { DiscountCode, SubscriptionData } from "@/core";
import { FC } from "react";
import { AspectRatio } from "@/components/ui/aspect-ratio.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { useTranslation } from "react-i18next";
import { formatPriceNormal } from "@/utils/format/format-price.ts";
import { calculateTotalSubscriptionPrice } from "@/utils/discount/calculate-subscription-total-price.ts";
import fallbackImage from "/fallback.png";

interface SubscriptionProductSummaryProps {
  subscription: SubscriptionData;
  discount: DiscountCode | null | undefined;
}

export const SubscriptionProductSummary: FC<
  SubscriptionProductSummaryProps
> = ({ subscription, discount }) => {
  const { t } = useTranslation("translation");

  const totalSubscriptionPrice = calculateTotalSubscriptionPrice(
    subscription,
    discount,
  );

  const subscriptionItems = Object.entries(
    subscription.subscriptionProduct.capsulesConfig,
  ).map(([key, value]) => {
    return `${key}: ${value}`;
  });

  return (
    <div
      className={"flex justify-between flex-wrap gap-4 items-center flex-row"}
    >
      <div className={"flex justify-start items-center gap-2 w-fit"}>
        <div className="w-24">
          <AspectRatio ratio={1 / 1}>
            <img
              src={
                subscription.subscriptionProduct.images.length > 0
                  ? subscription.subscriptionProduct.images[0]
                  : fallbackImage
              }
              alt="coffe box"
              className="rounded-sm w-full h-full object-cover"
            />
          </AspectRatio>
        </div>

        <div className={"w-fit justify-start items-start"}>
          <div className={" flex flex-col w-full"}>
            <div>
              <h3 className="font-hkgwb uppercase leading-none overflow-ellipsis">
                {subscription.subscriptionProduct.name.replace(
                  /-\sprenumerationsorder/gi,
                  "",
                )}
              </h3>
              <div
                className={
                  "flex flex-wrap sm:flex-row gap-1 text-sm font-extralight text-muted-foreground leading-none my-3"
                }
              >
                {subscriptionItems.map((item, index) => (
                  <div key={item}>
                    <span>{item}</span>
                    <span className={"mx-1"}>
                      {index < subscriptionItems.length - 1 && "/"}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <Badge variant={"default"} className={"font-roboto"}>
                {t(subscription.interval)}
              </Badge>
            </div>
          </div>
        </div>
      </div>
      <div className={"font-hkgwb uppercase flex justify-end"}>
        <span className={"text-lg"}>
          {formatPriceNormal(totalSubscriptionPrice)}
        </span>
      </div>
    </div>
  );
};
