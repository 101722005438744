import { FC, HTMLAttributes } from "react";
import { Link } from "react-router-dom";

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu.tsx";
import { Button } from "@/components/ui/button.tsx";
import { LocaleSwitcher } from "@/components/shared/lang-toggle.tsx";
// import { ModeToggle } from "@/components/mode-toggle.tsx";
import { ProfileDropdown } from "@/components/shared/ProfileDropdown.tsx";
import { cn } from "@/lib/utils.ts";

interface HeaderProps extends HTMLAttributes<HTMLDivElement> {}

interface NavigationItem {
  href: string;
  label: string;
}

export const navigationItems: NavigationItem[] = [
  // {
  //   href: "https://coffydoor.com/",
  //   label: "home",
  // },
  {
    href: "https://coffydoor.com/collections/bastsaljare",
    label: "Shop",
  },
  // {
  //   href: "https://coffydoor.com/pages/om-oss",
  //   label: "Om oss",
  // },
  {
    href: "https://coffydoor.com/pages/faq",
    label: "FAQ",
  },
  // {
  //   href: "https://coffydoor.com/pages/coffeclub",
  //   label: "Lojalitetsprogram",
  // },
  // {
  //   href: "https://coffydoor.com/pages/om-kaffet",
  //   label: "Om kaffet",
  // },
  // {
  //   href: "https://coffydoor.com/pages/for-foretag",
  //   label: "Företag",
  // },
];

export const Header: FC<HeaderProps> = (props) => {
  const { className } = props;

  return (
    <div className={className}>
      <div className={"hidden lg:flex lg:justify-between w-full p-6"}>
        <div className={"flex space-x-4 items-center"}>
          <NavigationMenu>
            <NavigationMenuList>
              {navigationItems.map((item, index) => (
                <NavItem key={index} href={item.href} label={item.label} />
              ))}
            </NavigationMenuList>
          </NavigationMenu>
        </div>

        <div className={"flex space-x-4 items-center"}>
          <ProfileDropdown />

          <LocaleSwitcher />
          {/*<ModeToggle />*/}
        </div>
      </div>
    </div>
  );
};

export const NavItem: FC<NavigationItem> = ({ href, label }) => {
  return (
    <NavigationMenuItem>
      <Button variant={"invisible"} className={"hover:bg-none"} asChild>
        <NavigationMenuLink
          asChild
          className={cn(navigationMenuTriggerStyle(), "uppercase")}
        >
          <Link to={href} target={"_blank"}>
            {label}
          </Link>
        </NavigationMenuLink>
      </Button>
    </NavigationMenuItem>
  );
};
