import defaultImage from "@/assets/images/home.jpg";
import { Button } from "@/components/ui/button.tsx";
import { Link } from "react-router-dom";
import { RoutePath } from "@/routes/constants/route-paths.ts";
import { useTranslation } from "react-i18next";
import { useAuthUser } from "@/hooks/service-hooks/auth/use-auth.ts";

export function LoginPage() {
  const { t } = useTranslation("translation");
  const authUser = useAuthUser();

  return (
    <div
      className={
        "flex flex-col-reverse justify-center lg:h-fit w-full mx-auto lg:grid lg:grid-cols-2 items-center"
      }
    >
      <div
        className="w-full flex flex-col z-20 relativet h-[80vh]"
        style={{
          backgroundImage: `url(${defaultImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute w-full p-8">
          <p className="font-hkgwl uppercase text-black text-xl">
            NO-NONSENSE COFFEE
          </p>
          <p className="font-hkgwb uppercase text-black text-2xl lg:text-4xl mt-2">
            EN KAFFEKAPSEL,
            <br />
            EN BRA START PÅ DAGEN
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-start lg:pl-24 z-20 h-full p-8">
        <h3
          className={
            "font-hkgwb uppercase text-2xl lg:text-6xl font-light pt-4"
          }
        >
          {t("myCoffydoorPortal")}
        </h3>
        <div className={"space-y-3 mt-6"}>
          {authUser ? (
            <Link to={RoutePath.SUBSCRIPTION}>
              <Button variant="black">{t("goToSubscription")}</Button>
            </Link>
          ) : (
            <a href={"https://coffydoor.com/account"}>
              <Button variant="black" className={"w-full"}>
                {t("login")}
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
