export default {
  translation: {
    loading: "Laddar...",
    loadingDescription:
      "Här visas informationen om ditt medlemskap om du har ett sådant på angiven e-post",
    enterYourEmail: "Ange e-post",
    enterYourPassword: "Ange lösenord",
    seeWhenIsYourNextDelivery: "Se nästa leveransdatum.",
    rootManageSubscription: "Hantera medlemskap, pausa, ändra och uppdatera.",
    userPoints: "Medlemspoäng",
    yes: "Bekräfta",
    home: "Hem",
    shop: "Shop",
    coffe: "Kaffet",
    faq: "FAQ",
    aboutUs: "Om oss",
    logOut: "Logga ut",
    cancelSubscription: "Avbryt prenumeration.",
    somethingElse: "Något annat?",
    whatsWrong: "Vad är fel?",
    itsTooExpensive: "Det är för dyrt",
    wrongOrder: "Felbeställning",
    tooMuchCoffee: "Jag har för mycket kaffe hemma",
    needFasterDelivery: "Jag behöver utskicket snabbare",
    cancelQuestion: "Varför vill du avbryta?",
    stoppedDrinkingCoffee: "Jag har slutat dricka kaffe",
    discountCodeDescription: "Ange din rabattkod här",
    discountCodeRemoved: "Rabattkoden har tagits bort",
    coffeeProblem: "Problem med kaffet",
    otherReason: "Annan anledning",
    back: "Gå tillbaka",
    reactivateSubscription: "Återaktivera prenumeration",
    areYouSureReActivateYourSubscription:
      "Är du säker på att du vill återaktivera din prenumeration?",
    editProfile: "Redigera profil",
    contact: "Kontakt",
    login: "Logga in",
    hello: "Välkommen",
    subtotal: "Delsumma",
    total: "Totalt",
    discount: "Rabatt",
    summary: "Sammanfattning",
    // Pages
    // ** Orders
    noOrdersFound: "Inga ordrar hittades",
    unpauseSubscription: "JAG BEHÖVER MER KAFFE NU",
    unpause: "JAG BEHÖVER MER KAFFE NU",
    noOrdersFoundDescription:
      "Du har inte lagt några ordrar ännu. Om du tror att detta är ett fel, kontakta support för ytterligare hjälp.",
    errorLoadingOrders: "Fel vid inläsning av ordrar",
    errorLoadingOrdersDescription:
      "Ett fel uppstod vid inläsning av dina ordrar. Försök igen senare.",
    // ** Customer
    noCustomerData: "Inga kunduppgifter hittades",
    noCustomerDataMessage:
      "Du har inte lagt några ordrar ännu. Om du tror att detta är ett fel, kontakta support för ytterligare hjälp.",
    customerError: "Kundfel",
    customerErrorMessage:
      "Ett fel uppstod vid inläsning av dina kunduppgifter. Försök igen senare.",
    failedToUpdateCustomerData: "Kunde inte uppdatera kunduppgifter",
    nextDeliveryDate: "Nästa leveransdatum uppdaterat",
    success: "Framgång",
    nextDeliveryDateIsBeforeToday: "Nästa leveransdatum är före dagens datum",

    // ** Subscription
    noSubscriptionFound: "Ingen prenumeration hittades",
    noSubscriptionFoundMessage:
      "Om du tror att detta är ett fel, kontakta support för ytterligare hjälp.",
    subscriptionError: "Prenumerationsfel",
    subscriptionErrorMessage:
      "Ett fel uppstod vid inläsning av dina prenumerationsuppgifter. Försök igen senare.",
    // ** Subscription

    capsules: "Kapslar",
    close: "Stäng",
    capsule: "kapsel",

    // ** Address
    noAddressFound: "Ingen adress hittades",
    noAddressFoundMessage:
      "Du har inte lagt till några adresser ännu. Om du tror att detta är ett fel, kontakta support för ytterligare hjälp.",
    addressError: "Adressfel",
    addressErrorMessage:
      "Ett fel uppstod vid inläsning av dina adressuppgifter. Försök igen senare.",
    // ** Address
    error: "Fel",
    anErrorOccurred: "Ett fel uppstod",
    // Pages End
    deleteDiscountCode: "Radera rabattkod",
    deleteDiscountCodeDescription:
      "Är du säker på att du vill radera rabattkoden?",
    subscriptionPaused: "Prenumerationen är pausad",
    subscriptionPausedDescription:
      "Din prenumeration är nu pausad. Du kan återuppta den när som helst.",
    subscriptionCancelled: "Prenumerationen är avbruten",
    subscriptionCancelledDescription:
      "Din prenumeration är nu avbruten. Du kan återuppta den när som helst.",
    pleaseEnterReason: "Vänligen ange en anledning",
    subscriptionList: "Prenumerationer",
    nextBillingDate: "Nästa debiteringsdatum",
    active: "Aktiv",
    ACTIVE: "Aktiv",
    PAUSED: "Pausad",
    subscriptionUnpaused: "Prenumerationen är återupptagen",
    subscriptionUnpausedDescription: "Din prenumeration är nu återupptagen",

    CANCELLED: "Avbruten",
    FAILED: "Misslyckades",
    update: "Uppdatera",
    areYouSure:
      "Din order kommer att debiteras och vi packar och skickar ditt paket omgående!\n",
    no: "Avbryt",
    billNow: "Jag behöver mer kaffe nu",
    billedNowSuccessfully: "Din prenumeration har uppdaterats",
    failedToBillNow: "Kunde inte uppdatera prenumerationen",
    buyWithCard: "Lägg till order ",
    youAre: "Du är",
    pointsAway: "Poäg ifrån",
    usePoints: "Använd medlemspoäng",

    spentPoints: "Få poäng för varje 10 sek spenderat",
    subscription: "Medlemskap",
    activity: "Aktivitet",
    manageSubscription: "Hantera medlemskap",
    orderHistory: "orderhistorisk ",
    myProfile: "Min profil",
    subscriptionDescription:
      "Visa kommande leverans. Passa på att addera något till din order!",
    nextDelivery: "Nästa leverans",
    usedPoints: "Använda poäng",
    additionalItems: "Extra produkter",
    yourNextDelivery: "Din nästa leverans skickas",
    shipsEveryWeek: "Skickas var Xe vecka",
    shippingAddress: "Leveransadress ",
    addToYourNextOrder: "Addera till nästa order",
    oneTime: "En gång",
    couponTooltip:
      "Bjud in en vän till Coffydoor och både du och din vän får 100 sek rabatt på kommande ordrar. ",
    couponTitle: "Ge 100 kr, få 100 kr!",
    manageSubscriptionDescription:
      "Hantera ditt medlemskap så att det passar just din kafferutin!",
    capsulesPackage:
      "Not sure what this means? But most likely it will be Kapselplan",
    yourCurrentPlanHas: "Din nuvarande plan har",
    updatePreferences: "Uppdatera medlemskap",
    everyTwoWeeks: "Var 2e vecka",
    everyFourWeeks: "Var 4e vecka",
    everySixWeeks: "Var 6e vecka",
    subscriptionUpdated: "Medlemskapet har uppdaterats",
    everyEightWeeks: "Var 8e vecka",
    everyTwelveWeeks: "Var 12e vecka",
    currentCapsules: "Din nuvarande plan är 120 kapslar.",
    deliveryFrequency: "Leveransfrekvens ",
    "twelve-weeks": "Var 12e vecka",
    "eight-weeks": "Var 8e vecka",
    "four-weeks": "Var 4e vecka",
    "six-weeks": "Var 6e vecka",
    "two-weeks": "Var 2e vecka",
    discountCodeApplied: "Rabattkod tillagd",
    customerUpdated: "Kunduppgifter uppdaterade",
    customerUpdateFailed: "Kunde inte uppdatera kunduppgifter",
    discountCodeError: "Ett fel uppstod vid användning av rabattkoden",
    discountCodeAppliedDescription: "Din rabattkod har lagts till",
    deliverySchedule: "Leveransschema ",
    discountCode: "Rabattkod",
    addItToYourNextOrder: "Lägg till med nästa leverans ",
    paymentMethod: "Betalmetod ",
    editBillingAddress: "Uppdatera fakturaadress ",
    changePaymentMethod: "Uppdatera betalmetod",
    changePaymentMethodMessage:
      "När du byter betalmetod behövs ett köp göras för att validera din betalmetod. Det är viktigt att du inte ändrar e-post eller telefon-nummer i kassan när du uppdaterar din betalmetod",
    deliveryAddress: "Leveransadress ",
    editDeliveryAddress: "Ändra leveransadress ",
    edit: "Ändra",
    nextDeliveryD: "Nästa leveransdatum",
    order: "Beställning",

    sorryToLeave:
      "Vi är ledsna att höra att du vill avbryta, men kan du vänligen meddela oss",
    areYouSureYouWantToPause:
      "Är du säker på att du vill pausa din prenumeration?",
    areYouSureYouWantToUnPause:
      "Är du säker på att du vill återuppta din prenumeration?",
    weWillCalculateOnNextDelivery:
      "Vi kommer att räkna ut detta på nästa leverans",
    pause: "Hoppa över leverans",
    pauseSubscription: "Hoppa över leverans",
    remove: "Ta bort",
    apply: "Applicera",
    skipDelivery: "Hoppa över leverans",
    applyDiscountCodeHere: "Applicera rabattkod här",
    cancelOrPause: "Hoppa över leverans eller avsluta",
    cancel: "Avsluta",
    cancelDelivery: "Avbryt leverans",
    iWantItNow: "Jag behöver mer kaffe nu",
    yourNextDeliveryWillBeAt: "Din nästa leverans kommer att vara den",
    copyCode: "Kopiera",
    product: "Produkt",
    january: "Januari",
    february: "Februari",
    march: "Mars",
    april: "April",
    may: "Maj",
    june: "Juni",
    july: "Juli",
    august: "Augusti",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "December",
    pointsTooltip: "Every 10 kr spent is 1 point",
    myDetails: "Mina uppgifter",
    membershipNumber: "Medlemsnummer",
    name: "Namn",
    phone: "Telefon",
    email: "E-post",
    firstName: "Förnamn",
    lastName: "Efternamn",
    country: "Land",
    city: "Stad",
    save: "Spara",
    change: "Ändra",
    changeAddress: "Ändra adress",
    postalCode: "Postnummer",
    changeDeliveryFrequency: "Ändra leveransfrekvens",
    changeCapsulesQuantity: "Ändra antal kapslar",
    changeCapsulesQuantityDescription:
      "Ändra antalet kapslar som du får i varje leverans",
    changeCapsulesBlends: "Ändra kapselblandningar",
    weeks: "vecka",
    updateYourNextDeliveryDate: "Uppdatera ditt nästa leveransdatum",
    editYourDiscountCode: "Ändra din rabattkod",
    areYouSureYouWantToCancel: "Är du säker på att du vill avbryta?",
    cancelSubscriptionSorryToHear: "Vi är ledsna att höra att du vill avbryta",
    selectReason: "Välj en anledning",
    letUsKnowHowWeCanImprove: "Låt oss veta hur vi kan förbättra",
    cancelSubscriptionDescription:
      "Vid avslutat medlemskap kommer dina intjänade medlemspoäng och ditt rabatterade medlemspris gå förlorat.\n",
    cancelSubscriptionSecondDescription:
      "Pausa istället ditt medlemskap och behålla alla dina förmåner!",
    noIDontWant: "Nej, jag vill inte",
    beAlwaysFresh: "En garanterat bra start på dagen",
    myCoffydoorPortal: "Dags för påtår?",
    logIn: "Logga in",
    allSubscriptions: "Alla prenumerationer",
    discountCodes: "Rabattkoder",
    editDiscountCodes: "Ändra rabattkoder",
    type: "Typ",
    status: "Status",
    delete: "Radera",
    search: "sök",
    noResults: "Inga resultat",
    reset: "Återställ",
    customer: "Kund",
    subscriptionActivity: "Prenumerationsaktivitet",
    describeBillNow: "Du kommer att faktureras för nästa månad omedelbart.",
    subscriptionInformation: "Prenumerationsinformation",
    price: "Pris",
    frequency: "Frekvens",
    nextChargeDate: "Nästa debiteringsdatum",
    editSubscriptionPage: "Redigera prenumerationssida",
    interval: "Intervall",
    updateNextDeliveryDate: "Uppdatera nästa leveransdatum",
    subscriptionFailedToUpdate: "Prenumerationen kunde inte uppdateras",
    removeAddon: "Ta bort tillägg",
    changeAddon: "Ändra tillägg",
    address: "Adress",
    zipCode: "Postnummer",
    province: "Provins",
    company: "Företag",
    phoneNumber: "Telefonnummer",
    editDeliveryDetails: "Redigera leveransinformation",
    saveChanges: "Spara ändringar",
    createDiscountCode: "Skapa rabattkod",
    code: "Kod",
    used: "Används",
    limit: "Gräns",
    limitType: "Gränstyp",
    title: "Titel",
    value: "Rabattkodens värde",
    limitedUsage: "Begränsad användning",
    startedDate: "Startdatum",
    endDate: "Slutdatum",
    doesItHaveEndDate: "Har det ett slutdatum?",
  },
};
