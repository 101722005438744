import { FC, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import fallbackImage from "/fallback.png";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Separator } from "@/components/ui/separator.tsx";

import { formatPriceNormal } from "@/utils/format/format-price.ts";

import { Order } from "@/core";
import { AspectRatio } from "@/components/ui/aspect-ratio.tsx";

interface OrderHistoryProps extends HTMLAttributes<HTMLDivElement> {
  order: Order;
}

export const OrderHistory: FC<OrderHistoryProps> = ({ order }) => {
  const { t } = useTranslation("translation");

  const hasLineItems = order.lineItems.length > 0;
  const hasImage = hasLineItems && order.lineItems[0].images.length > 0;

  const orderPrice = order.discount
    ? order.totalPrice.amount - order.discount.amount
    : order.totalPrice.amount;

  const hasName = hasLineItems && order.lineItems[0].name.length > 0;

  return (
    <Card className={"grid grid-cols-1 lg:grid-cols-8"}>
      <CardHeader className={"w-full px-6 pt-6 pb-0 lg:p-6 col-span-2"}>
        <CardTitle className={"font-hkgwb uppercase"}>{t("order")}</CardTitle>
        <p className={"text-muted-foreground font-light"}></p>
        <p className={"font-hkgwl uppercase"}>
          {" "}
          {new Date(order.createdAt).toDateString()}
        </p>
        <Separator className={"lg:hidden w-full my-5"} />
      </CardHeader>
      <CardContent className={"pb-0 col-span-4"}>
        {/*<CardTitle className={"text-lg"}>{t("product")}</CardTitle>*/}
        <div className={"mt-2"}>
          <div className={"grid grid-cols-3 gap-2 w-full"}>
            <AspectRatio className={"col-span-1"} ratio={1}>
              <img
                src={hasImage ? order.lineItems[0].images[0] : fallbackImage}
                alt={`coffe product - ${order.lineItems[0].name}`}
                className="rounded-sm border shadow-sm aspect-auto object-contain"
              />
            </AspectRatio>

            <div className={"col-span-2 w-full"}>
              <p className={"hyphens-auto text-wrap font-hkgwb uppercase"}>
                {hasName ? order.lineItems[0].name : ""}
              </p>
              <p
                className={
                  "text-xs text-muted-foreground font-light break-words"
                }
              >
                {/*TODO: Add description if its available*/}
              </p>
            </div>
          </div>
          {/*{additionalProducts &&*/}
          {/*  additionalProducts.map((adProduct, index) => (*/}
          {/*    <div key={index} className={"flex pl-6 justify-start"}>*/}
          {/*      <AspectRatio ratio={16 / 9} className={"w-20 h-20"}>*/}
          {/*        <img*/}
          {/*          src={adProduct.images[0]}*/}
          {/*          alt={`additional products - ${adProduct.name}`}*/}
          {/*          className="rounded-sm object-cover"*/}
          {/*        />*/}
          {/*      </AspectRatio>*/}
          {/*      <div className={"ml-4"}>*/}
          {/*        <div className={"grid sm:flex gap-2"}>*/}
          {/*          <p>{adProduct.name}</p>*/}
          {/*          <Badge*/}
          {/*            className={"w-20 hidden sm:block"}*/}
          {/*            variant={"outline"}*/}
          {/*          >*/}
          {/*            One time*/}
          {/*          </Badge>*/}
          {/*        </div>*/}
          {/*        <p*/}
          {/*          className={*/}
          {/*            "text-sm text-muted-foreground font-light break-words"*/}
          {/*          }*/}
          {/*        >*/}
          {/*          {adProduct.quantity} | {formatPrice(adProduct.price.amount)}*/}
          {/*        </p>*/}
          {/*        <Badge className={"w-20 sm:hidden"} variant={"outline"}>*/}
          {/*          One time*/}
          {/*        </Badge>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  ))}*/}
        </div>
        <Separator className={"lg:hidden w-full mt-6"} />
      </CardContent>
      <CardFooter
        className={"col-span-2 py-1 flex flex-col items-center justify-start"}
      >
        <div className={"text-lg justify-items-center uppercase font-hkgwl"}>
          {t("total")}
        </div>
        <p className={"uppercase font-hkgwl"}>
          {formatPriceNormal(orderPrice)}
        </p>
      </CardFooter>
    </Card>
  );
};
