import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button, buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils.ts";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FC, HTMLAttributes, useEffect, useState } from "react";
import { Icons } from "../ui/icons";

interface DestructiveAlertProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string;
  triggerText: string;

  cancelAction?: () => void;
  cancelText: string;
  confirmVariant?:
    | "black"
    | "active"
    | "default"
    | "destructive"
    | "bill"
    | "outline"
    | "secondary"
    | "ghost"
    | "link"
    | "invisible"
    | null
    | undefined;
  confirmAction: () => void;
  confirmActionLoading?: boolean;
  confirmText: string;
  isActive?: boolean;

  isDropdown?: boolean;
  textVerificationValue?: string;
}

export function DestructiveAlert({
  triggerText,
  title,
  description,
  cancelText,
  confirmText,
  cancelAction,
  isActive,
  confirmAction,
  isDropdown = true,
  textVerificationValue,
  confirmActionLoading,
  className,
  confirmVariant,
}: DestructiveAlertProps) {
  const [isFormValid, setIsFormValid] = useState(false);

  return (
    <AlertDialog>
      <AlertDialogTrigger
        asChild={!isDropdown}
        className={cn(
          className,
          isDropdown
            ? "relative w-full flex cursor-default text-destructive hover:bg-accent select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
            : "",
        )}
      >
        {!isDropdown ? (
          <Button
            disabled={confirmActionLoading}
            variant={isActive ? "black" : "destructive"}
          >
            {confirmActionLoading && <Icons.loading />}
            {triggerText}
          </Button>
        ) : (
          triggerText
        )}
      </AlertDialogTrigger>

      <AlertDialogContent
        className={"lg:max-w-3xl max-w-[90vw] max-h-[70vh] rounded-lg"}
      >
        <AlertDialogHeader>
          <AlertDialogTitle className={"font-hkgwb uppercase"}>
            {title}
          </AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>

        {textVerificationValue && (
          <DestructiveVerificationForm
            setIsValid={setIsFormValid}
            textVerificationValue={textVerificationValue}
          />
        )}
        <AlertDialogFooter
          className={"flex flex-wrap w-full max-w-full mx-auto flex-col gap-3"}
        >
          <AlertDialogCancel asChild>
            <Button onClick={cancelAction} variant="outline">
              {cancelText}
            </Button>
          </AlertDialogCancel>

          <AlertDialogAction
            className={cn(
              buttonVariants({
                variant: confirmVariant ? confirmVariant : "destructive",
              }),
            )}
            asChild
          >
            <Button
              disabled={!isFormValid && !!textVerificationValue}
              onClick={confirmAction}
              variant={confirmVariant ? confirmVariant : "destructive"}
            >
              {confirmActionLoading && <Icons.loading />}
              {confirmText}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

interface DestructiveVerificationFormProps {
  setIsValid: (value: boolean) => void;
  textVerificationValue: string;
}
const formSchema = z.object({
  verificationText: z.string(),
});

const DestructiveVerificationForm: FC<DestructiveVerificationFormProps> = ({
  setIsValid,
  textVerificationValue = "DELETE",
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      verificationText: "",
    },
    mode: "onChange",
  });

  const value = form.watch("verificationText");
  useEffect(() => {
    if (!value) {
      setIsValid(false);
    }
    if (value) {
      setIsValid(value === textVerificationValue);
    }
  }, [
    form.formState.touchedFields.verificationText,
    setIsValid,
    textVerificationValue,
    value,
  ]);

  return (
    <Form {...form}>
      <form className="space-y-8 my-4">
        <FormField
          control={form.control}
          name="verificationText"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Please type{" "}
                <span className="text-destructive">
                  "{textVerificationValue}"
                </span>{" "}
                to confirm.
              </FormLabel>
              <FormControl>
                <Input placeholder={textVerificationValue} {...field} />
              </FormControl>
              <FormDescription>
                This action is irreversible. Please be careful.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
};
