import { FC } from "react";
import { useAuthUser, useGetCustomerPoints } from "@/hooks";
import { LoadingState } from "@/components/shared/LoadingState.tsx";
import { ErrorState } from "@/components/shared/ErrorState.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { Icons } from "@/components/ui/icons.tsx";
import { DiscountCode, SubscriptionData } from "@/core";
import { CircleDashed } from "lucide-react";
import { useTranslation } from "react-i18next";
import { formatPriceNormal } from "@/utils/format/format-price.ts";
import { calculateTotalSubscriptionPrice } from "@/utils/discount/calculate-subscription-total-price.ts";

interface SubscriptionSummaryProps {
  subscription: SubscriptionData;
  discount: DiscountCode | null | undefined;
  isDiscountCodeLoading: boolean;
  isDiscountCodeError: boolean;
}

export const SubscriptionSummary: FC<SubscriptionSummaryProps> = ({
  subscription,
  discount,
  isDiscountCodeLoading,
  isDiscountCodeError,
}) => {
  const { t } = useTranslation("translation");

  const totalSubscriptionPrice = calculateTotalSubscriptionPrice(
    subscription,
    discount,
  );

  return (
    <div className={"space-y-4"}>
      <div className={"flex justify-between text-xs sm:text-sm font-light"}>
        <p>
          {t("subscription")} {t("price")}
        </p>
        <p>
          {formatPriceNormal(subscription.subscriptionProduct.price.amount)}
        </p>
      </div>
      <SummaryDiscount
        discount={discount}
        isDiscountCodeLoading={isDiscountCodeLoading}
        isDiscountCodeError={isDiscountCodeError}
      />
      <SubscriptionSummaryPoints />
      <div
        className={
          "flex justify-between text-xs sm:text-lg font-hkgwb uppercase"
        }
      >
        <p>
          {t("total")} {t("price")}
        </p>
        <p className={"font-black"}>
          {formatPriceNormal(totalSubscriptionPrice)}
        </p>
      </div>
    </div>
  );
};

const SubscriptionSummaryPoints = () => {
  const authUser = useAuthUser();
  const { t } = useTranslation("translation");

  const customerPoints = useGetCustomerPoints({
    id: authUser?.uid || "",
  });

  if (!customerPoints.data || customerPoints.isLoading) {
    return null;
  }

  return (
    <div className={"flex justify-between text-xs sm:text-sm font-light"}>
      <p>{t("userPoints")}</p>
      {customerPoints.isLoading ? (
        <CircleDashed className={"w-4 h-4 animate-spin"} />
      ) : (
        <span className={"flex gap-2 items-center"}>
          {customerPoints?.data?.points || 0}{" "}
          <Icons.star className={"w-4 h-4"} />
        </span>
      )}
    </div>
  );
};

interface SummaryDiscountProps {
  discount: DiscountCode | null | undefined;
  isDiscountCodeLoading: boolean;
  isDiscountCodeError: boolean;
}

const SummaryDiscount: FC<SummaryDiscountProps> = ({
  discount,
  isDiscountCodeLoading,
  isDiscountCodeError,
}) => {
  const { t } = useTranslation("translation");

  if (isDiscountCodeLoading) {
    return <LoadingState />;
  }

  if (isDiscountCodeError) {
    return <ErrorState />;
  }

  if (!discount) {
    return null;
  }

  return (
    <div
      className={
        "flex justify-between items-center text-xs sm:text-sm font-light"
      }
    >
      <div>
        <div className={"flex gap-2 items-center"}>
          <p>
            {t("discount")} {t("code")}
          </p>
          <Badge variant={"default"}>{discount.code}</Badge>
        </div>
      </div>
      <p>
        - {discount.amount} {discount.amountType === "percentage" ? "%" : "SEK"}
      </p>
    </div>
  );
};
